import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import Loading from "./components/Loading";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// import Home from "./containers/Home";
// const AsyncHome = asyncComponent(() => import("./containers/Home"));
const AsyncHome = Loadable({
  loader: () => import("./containers/Home"),
  loading: Loading
});

// import NewNote from "./containers/NewNote";
// const AsyncNewNote = asyncComponent(() => import("./containers/NewNote"));
const AsyncNewNote = Loadable({
  loader: () => import("./containers/NewNote"),
  loading: Loading
});

// import Notes from "./containers/Notes";
// const AsyncNotes = asyncComponent(() => import("./containers/Notes"));
const AsyncNotes = Loadable({
  loader: () => import("./containers/Notes"),
  loading: Loading
});

// import Login from "./containers/Login";
// const AsyncLogin = asyncComponent(() => import("./containers/Login"));
const AsyncLogin = Loadable({
  loader: () => import("./containers/Login"),
  loading: Loading
});

// import Signup from "./containers/Signup";
// const AsyncSignup = asyncComponent(() => import("./containers/Signup"));
const AsyncSignup = Loadable({
  loader: () => import("./containers/Signup"),
  loading: Loading
});

// import Settings from "./containers/Settings";
// const AsyncSettings = asyncComponent(() => import("./containers/Settings"));
const AsyncSettings = Loadable({
  loader: () => import("./containers/Settings"),
  loading: Loading
});

// import ResetPassword from "./containers/ResetPassword";
// const AsyncResetPassword = asyncComponent(() => import("./containers/ResetPassword"));
const AsyncResetPassword = Loadable({
  loader: () => import("./containers/ResetPassword"),
  loading: Loading
});

// import ChangePassword from "./containers/ChangePassword";
// const AsyncChangePassword = asyncComponent(() => import("./containers/ChangePassword"));
const AsyncChangePassword = Loadable({
  loader: () => import("./containers/ChangePassword"),
  loading: Loading
});

// import ChangeEmail from "./containers/ChangeEmail";
// const AsyncChangeEmail = asyncComponent(() => import("./containers/ChangeEmail"));
const AsyncChangeEmail = Loadable({
  loader: () => import("./containers/ChangeEmail"),
  loading: Loading
});

// import NotFound from "./containers/NotFound";
// const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));
const AsyncNotFound = Loadable({
  loader: () => import("./containers/NotFound"),
  loading: Loading
});

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={AsyncHome} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={AsyncLogin} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={AsyncResetPassword} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={AsyncSignup} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={AsyncSettings} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={AsyncChangePassword} props={childProps} />
    <AuthenticatedRoute path="/settings/email" exact component={AsyncChangeEmail} props={childProps} />
    <AuthenticatedRoute path="/notes/new" exact component={AsyncNewNote} props={childProps} />
    <AuthenticatedRoute path="/notes/:id" exact component={AsyncNotes} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={AsyncNotFound} />
  </Switch>;
